import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"

const GoTopButton = () => {
  return (
    <a href="#top" alt="Ir al inicio">
      <div className="hidden md:block fixed bottom-8 right-8 w-16 h-16 text-2xl bg-blue text-center rounded-full text-white">
        <div className="w-full h-full flex items-center justify-center">
          <FontAwesomeIcon
            className="w-[16px] h-[16px] fill-current text-center"
            icon={faChevronUp}
          />
        </div>
      </div>
    </a>
  )
}

export default GoTopButton
