import React from "react"
import { StaticQuery, graphql } from "gatsby"

const mapsUrl = (lat, len) =>
  `https://www.google.com/maps/search/?api=1&query=${lat}%2C${len}`

const Events = ({ events }) => {
  return (
    <section
      id="shows"
      className="w-full mb-24"
    >
      <ul className="max-w-6xl mx-auto md:px-6 lg:px-32">
        <div className="flex flex-row justify-between"></div>
        {events
          .sort(
            (event1, event2) =>
              new Date(event1.data.date) - new Date(event2.data.date)
          )
          .map(({ data }) => {
            const {
              agotado,
              venue_name: [{ text: name }],
              city: [{ text: city }],
              country: [{ text: country }],
              // tour_name: [{ text: tour }],
              external_url: { url },
              event_google_maps_location: { latitude, longitude },
              date,
            } = data

            const dateObj = new Date(date.replace(/-/g, '\/'))
            const month = dateObj.toLocaleString("default", { month: "short" })
            const day = dateObj.getDate()

            const isValidLocation = typeof latitude === 'number' && typeof longitude === 'number' && latitude !== 0 && longitude !== 0;

            return (
              <li key={name+city+date} className="py-6 text-center">
                <div className="flex flex-col gap-y-7 md:flex-row justify-between items-center">
                  <div className="w-full md:w-24 md:h-24 flex flex-col bg-blue py-2 px-4 items-center justify-center">
                    <div className="text-center text-white">{month}</div>
                    <div className="text-center text-white text-3xl font-bold">
                      {day}
                    </div>
                  </div>
                  <div className="flex flex-col align-center justify-center text-center">
                    <a
                      href={
                        isValidLocation
                          ? mapsUrl(latitude, longitude)
                          : "#"
                      }
                      target="_blank"
                      onClick={e => !isValidLocation && e.preventDefault()}
                    >
                      <div className="text-2xl md:text-4xl xl:text-5xl font-black text-blue font-serif italic">
                        {name}
                      </div>
                    </a>
                    <div>
                      <a
                        href={
                          isValidLocation
                            ? mapsUrl(latitude, longitude)
                            : "#"
                        }
                        target="_blank"
                        onClick={e => !isValidLocation && e.preventDefault()}
                      >
                        <span className="font-bold">{city}</span>,{" "}
                        <span className="font-bold opacity-40">{country}</span>
                      </a>
                    </div>
                  </div>
                  <div className="flex-none">
                    <div className="flex flex-col justify-center items-center h-full">
                      {agotado ? (
                        <span className="line-through text-red">Agotado</span>
                      ) : (
                        <a
                          className="text-xl bg-transparent hover:bg-blue text-blue font-semibold hover:text-white px-4 py-2 border border-blue hover:border-transparent rounded-full"
                          href={url}
                          target="_blank"
                        >
                          Tickets
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
      </ul>
    </section>
  )
}

/*
place the following graphql query between date and event_google_maps_location
              event_flyer {
                dimensions {
                  width
                  height
                }
                url
              }
*/

const EventsWrapper = () => (
  <StaticQuery
    query={graphql`
      {
        events {
          results {
            data {
              agotado
              city {
                text
              }
              country {
                text
              }
              date
              event_google_maps_location {
                latitude
                longitude
              }
              external_url {
                url
              }
              tour_name {
                text
              }
              venue_name {
                text
              }
            }
          }
        }
      }
    `}
    render={data => <Events events={data.events.results} />}
  ></StaticQuery>
)

export default EventsWrapper
