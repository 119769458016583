import React, { useEffect, useState, useLayoutEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby" // to query for image data
import Img from "gatsby-image" // to take image data and render it

import tourLogo from "../images/tour-logo.png"
import SocialIcons from "../components/SocialIcons"

const links = {
  facebook: "https://facebook.com/irepelusa",
  twitter: "https://twitter.com/irepelusa",
  tiktok: "https://www.tiktok.com/@irepelusi",
  instagram: "https://instagram.com/irepelusa",
  youtube: "https://www.youtube.com/channel/UCO_PoStQDgnvIwKFSjP03uw",
  spotify: "https://open.spotify.com/artist/3KaNWDYObY73SDpcZBRzuw",
  deezer: "https://www.deezer.com/es/artist/65082662",
}

const Hero = ({ setNavBg }) => {
  const data = useStaticQuery(graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "hero" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid (maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`)

  const refRef = useRef(null)
  const [currentBg, setCurrentBg] = useState(1)
  const [scrollPosition, setScrollPosition] = useState(0)

  const onScroll = () => {
    const { top, height: containerHeight } =
      refRef.current.getBoundingClientRect()
    if (top === 0) {
      setNavBg(false)
    } else {
      setNavBg(true)
    }
    if (window.innerHeight < window.innerWidth) {
      const percentage = Math.round((top * -1 * 100) / containerHeight)
      setScrollPosition(percentage)
    }
  }

  useLayoutEffect(() => {
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [refRef])

  useEffect(() => {
    const timer = setTimeout(() => {
      const result = currentBg + 1
      if (result > 3) {
        setCurrentBg(1)
      } else {
        setCurrentBg(result)
      }
    }, 3500)
    return () => clearTimeout(timer)
  }, [currentBg])

  const buildBackgrounds = () => {
    const sorted = data.allFile.edges.sort((image1, image2) => image1.node.base - image2.node.base);
    return sorted.map((image, index) => {

      return (
        <Img
          fluid={image.node.childImageSharp.fluid}
          alt={"Un koala musical"} // only use section of the file extension with the filename
          key={image.node.base}
          className="transition-all z-0 absolute h-full w-full object-cover sm:w-full sm:h-auto"
          src={image}
          style={{
            margin: `calc(-${scrollPosition}%) 0px`,
            opacity: currentBg === (index + 1) ? 1 : 0,
            transition: "opacity 1s ease-in-out",
            position: 'absolute'
          }}
          imgStyle={{ opacity: 1 }}
        />
      )
    })
  }

  return (
    <section
      className="h-screen pb-14 z-0 relative overflow-hidden"
      ref={refRef}
      id="top"
    >
      {buildBackgrounds()}
      <div
        className="absolute h-full w-full z-10"
        style={{
          background: 'linear-gradient(0deg, rgba(0,177,230,0) 0%, rgba(0,177,230,1) 100%)'
        }}
      />
      <div className="container lg:px-6 mx-auto mt-12 lg:mt-24 flex flex-wrap flex-row items-center justify-between h-full sm:w-3/5 relative">
        <img
          alt="Irepelusa Koala Tour"
          src={tourLogo}
          className="absolute z-20 transition-all top-0"
          style={{ marginLeft: `calc(-${scrollPosition}% * 3)` }}
        />
        <div className="absolute bottom-0 lg:mb-[8%] w-full grow flex flex-col justify-end lg:justify-between">
          <div className="w-full z-20 text-center pb-[24px] transition-all">
            <a
              href="#shows"
              style={{
                opacity: scrollPosition < 20 ? 1 : 0,
              }}
              className="transition-all text-center md:h-12 md:w-48 bg-blue hover:bg-blue text-white font-semibold hover:text-white py-2 px-4 border border-blue hover:border-transparent rounded-full"
            >
              Comprar Tickets
            </a>
          </div>
          <div className="w-full z-20 text-center transition-all pb-2">
            <SocialIcons
              links={links}
              style={{
                opacity: scrollPosition < 20 ? 1 : 0,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero;
