import React from "react"
import SocialIcon from "./SocialIcon"
import { faInstagram, faTwitter, faSpotify, faYoutube, faTiktok } from "@fortawesome/free-brands-svg-icons"

const SocialIcons = ({ links, className='', style={} }) => {
  return ( 
    <ul className={`text-blue ${className}`} style={style}>
      <SocialIcon href={links.instagram} icon={faInstagram} label="Instagram" />
      <SocialIcon href={links.tiktok} icon={faTiktok} label="TikTok" />
      <SocialIcon href={links.youtube} icon={faYoutube} label="YouTube" />
      <SocialIcon href={links.spotify} icon={faSpotify} label="Spotify" />
    </ul>
  )
}

export default SocialIcons;
