import React from "react"
import koala from "../images/koala.png"
import { useStaticQuery, graphql } from "gatsby" // to query for image data
import Img from "gatsby-image" // to take image data and render it

const ImageBig = ({ imgNum, className }) => {
  const data = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "hero/1.png" }) {
        childImageSharp {
          fluid(maxWidth: 720, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      img2: file(relativePath: { eq: "hero/2.png" }) {
        childImageSharp {
          fluid(maxWidth: 720, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      img3: file(relativePath: { eq: "hero/3.png" }) {
        childImageSharp {
          fluid(maxWidth: 720, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data[`img${imgNum}`].childImageSharp.fluid}
      alt="A nice Koala being a nice Koala"
      className={className}
    />
  )
}

const Bio = () => {
  return (
    <section id="bio" className="max-w-6xl mx-auto pt-24 text-sm">
      <div className="text-center p-6">
        <img src={koala} className="w-24 h-24 mx-auto" />
      </div>
      <div className="flex flex-col lg:flex-row my-0 px-0 md:px-24 mb-24 items-center">
        <div className="flex flex-col justify-between p-8 w-full md:w-1/2">
          <p className="opacity-80">
            Irepelusa se ha posicionado como una de las nuevas intérpretes con mayor proyección en la escena de la música Pop y Urbana de esta generación en Latinoamérica. Sus canciones están diseñadas usando la mezcla de ritmos caribeños y géneros americanos como lo es el Hip - Hop, R&B y el Neo - Soul, pero siempre manteniéndose dentro del mundo Pop. El encuentro de estos mundos nos da como resultado una sensación cálida, divertida y sensual que permiten disfrutar de su música en cualquier mood.
            <br />
            <br />
            En el 2022, Irepelusa lanzó su primer álbum como solista, este álbum se le dió el nombre de "UN KOALA EN UNA PALMERA". El lanzamiento fue reconocido por la comunidad artística por su autenticidad, y logró consolidar un conglomerado de fans alrededor de todo el continente, que tuvo la oportunidad de verla cantar en 2022 y 2023 en una gira que con tan solo su primer álbum le dio para hacer shows en Santiago (2 Sold Outs), Buenos Aires (Sold Out), Bogotá (Sold Out), Quito, Lima (Sold Out), Ciudad de México, Puebla, Guadalajara, San Salvador, San José, Ciudad de Guatemala y San Juan, cautivando a cada uno de los espectadores.
          </p>
          <div className="flex flex-row pt-12 gap-x-6">
            <div className="w-full md:w-1/2">
              <ImageBig imgNum={3} className="object-contain pl-3" />
            </div>
            <div className="w-full md:w-1/2">
              <ImageBig imgNum={2} className="object-contain pl-3" />
            </div>
          </div>
        </div>
        <div className="p-6 w-full md:w-1/2">
          <ImageBig imgNum={1} className="object-contain pl-3" />
          <br />
          <br />
          <p className="opacity-80">
            Irepelusa también tuvo la oportunidad de colaborar de la mano con Spotify en dos campañas regionales: "EQUAL" (2023) y "Artista Radar Andes" (2020). A raíz de la campaña "EQUAL", Irepelusa se convirtió en una embajadora de la campaña para promover el talento musical en las mujeres y darles mayor visibilidad en la industria.
            <br />
            <br />
            En 2023, Irepelusa ha estado dedicada a hacer su nuevo disco de la mano el gran productor Juan Pablo Vega (Productor Del Año Latin Grammys en 2019) y ha estado haciendo algunas colaboraciones venideras que estarán presentes en su segundo álbum que se comenzará a lanzar a finales del año 2023. Adicionalmente, está preparando y confirmando gira por Latinoamérica, México y España para el año 2024 para seguir cautivando a su público mediante sus shows que contienen una puesta en vivo de mucho poder y seducción.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Bio
