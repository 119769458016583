import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


const SocialIcon = ({ href, label, icon }) => {
  const linkParams = { href }

  if (href.startsWith("http")) {
    linkParams.target = "_blank"
    linkParams.rel = "noreferrer noopener"
  }

  return (
    <li className="inline-block px-2">
      <a
        {...linkParams}
        className="inline-flex h-8 w-8 md:h-12 md:w-12 border border-blue text-white rounded-full items-center justify-center transition-colors duration-200 hover:text-white bg-blue hover:bg-blue hover:border-blue"
      >
        <span className="sr-only">{label}</span>
        <FontAwesomeIcon className="w-4 h-4 md:w-6 md:h-6 fill-current" icon={icon} />
      </a>
    </li>
  )
}
export default SocialIcon;
