import React, { useState } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Events from "../components/Events"
import Bio from "../components/Bio"
import GoTopButton from "../components/GoTopButton"

const IndexPage = () => {
  const [navBg, setNavBg] = useState(false)

  return (
    <Layout navClass={navBg ? "bg-black/90 p-3 md:-p-0 md:pb-2 text-white" : "p-3 md:p-6 text-white"}>
      <Hero setNavBg={setNavBg} />
      <Bio />
      <Events />
      <GoTopButton />
    </Layout>
  )
}

export default IndexPage
